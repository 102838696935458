import React from 'react';
import createProfileItem from './ProfileItem';
import resume from './resume';

function Projects() {
    return <section className="white-section" id="project">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-3">
                    <h1>Project</h1>
                </div>
                <div className="col-lg-9">
                    {resume.projects.map(createProfileItem)}
                </div>
            </div>
        </div>
    </section>
}

export default Projects;