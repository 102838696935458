const resume =
{
    experience: [
        {
            name: "PAIR Lab",
            position: "Research Assistant",
            descr: ["Developing an image classification system for unmanned aerial vehicles (UAVs) with Professor Liujun Li to promote the automation of weed control",
                "Optimizing and fine-tuning deep learning models, including YOLO and MobileNet, and trained them with diverse datasets from multiple sources",
                "Developing an automated process for weed control using unmanned aerial vehicles (UAVs), integrating image classification technology with automated robots",
                "Building web-based data pipeline for automated data processing of IOT sensors' data"],
            time: "Feb 2023 - Present"
        },
        {
            name: "Future of Programming Lab",
            position: "Research Assistant",
            descr: ["Developed a web-based live functional programming environment(Hazel) with Professor Cyrus Omar",
                "Improved the structure editing feature by formalizing specification of various Hazel mechanisms with theorem proofs and implementing with js_of_ocaml and ReasonML",
                "Optimized timeout hole feature by upgrading the logic judgement function of input evaluator"],
            time: "May 2022 - Feb 2023"
        },
        {
            name: "Bank of Communications Co.,Ltd.",
            position: "Summer Internship",
            descr: ["Collaborated closely with engineers to deliver quality data and analysis to empower the team to effectively make more accurate data-driven decisions",
                "Visualized data with Python and Excel to provide supportive documents for conference and operation management",
                "Digitized and organized paper documents of various clients in the archives to improve data accessibility"],
            time: "Jun 2021 - Aug 2021"
        }
    ],
    projects: [
        {
            name: "Monocular Depth Estimation",
            position: "",
            descr: ["Estimated depth map from monocular RGB images by implementing and training neural networks using Python",
                "Reconstructed a 3D model by developing a visualization tool integrating the depth maps from the trained model, and applied the 3D model to evaluate the trained models' performance"],
            time: "Winter 2022"
        },
        {
            name: "ML Family Language Interpreter",
            position: "",
            descr: ["Designed and built an interpreter for an ML family language with local and dynamic type inference using OCaml",
                "Utilized polymorphic lambda calculus, local type inference, recursive expression, and parametric polymorphism during the implementation, allowing the language to be Turing Complete and be part of the ML family"],
            time: "Winter 2022"
        },
        {
            name: "DubsTech Datathon",
            position: "",
            descr: ["Collaborated with four team members to analyze the spread of Covid-19",
                "Utilized Python for data processing and visualization to identify the trend and issues in each states",
                "Provided analysis and suggestion report to judges, and won the second place among 30 teams"],
            time: "May 2020"
        },
        {
            name: "ColorFight AI Competition",
            position: "",
            descr: ["Built bot fighting in a round-based territory expansion game, designed and implemented an Artificial Intelligence bot using algorithm of Gomoku",
                "Won the third prize against 10+ participants at the final round"],
            time: "Nov 2018"
        }
    ]
};

export default resume;