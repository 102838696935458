import React from 'react';
import createProfileItem from './ProfileItem';
import resume from './resume';

// function createProfileItem(content) {
//     return <ProfileItem
//     name= {content.name}
//     position={content.position}
//     descr={content.descr}
//     time={content.time} />;
// }

function Experiences() {
    return <section className="light-section" id="experience">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-3">
                    <h1>Experience</h1>
                </div>
                <div className="col-lg-9">
                    {resume.experience.map(createProfileItem)}
                </div>
            </div>
        </div>
    </section>
}

export default Experiences;