import React from 'react';
import './App.css';
import Navbar from './Navbar';
import Footer from './Footer';
// import DWApp from '../DW Website/App'
import Projects from './Projects';
import Experiences from './Experiences';
import Educations from './Educations';
import Summary from './Summary';

function App() {
    return (
        <div>
            <Navbar />

            <Summary />

            <Educations />

            <Experiences />

            <Projects />

            <Footer />
        </div>
    );
}

export default App;
