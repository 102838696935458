import React from 'react';

function Summary() {
    return <section className="colored-section" id="summary">
        <div className="container-fluid center-section">
            <div className="row">
                <div className="col-lg-3">
                </div>
                <div className="col-lg-7">
                    <h1>Hey, I'm Donglin Zhou</h1>
                    <h6>I'm a Computer Science student at New York University.</h6>
                </div>
            </div>
        </div>
    </section>
}

export default Summary;