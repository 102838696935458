import React from 'react';

function Educations() {
    return <section className="white-section" id="education">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-3">
                    <h1>Education</h1>
                </div>
                <div className="col-lg-9">
                    <div className="row row-item">
                        <div className="col-lg-10">
                            <img className="univ-logo" src="NYU-Logo.png" alt="" />
                            <span className="univ-item"><strong>New York University</strong></span>
                            <span><em>M.S. in Computer Science</em></span>
                        </div>
                        <div className="col-lg-2  ms-auto">
                            <span>2025(Expected)</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10">
                            <img className="univ-logo" src="UM-Logo.png" alt="" />
                            <span className="univ-item"><strong>University of Michigan</strong></span>
                            <span><em>B.S. in Computer Science</em></span>
                        </div>
                        <div className="col-lg-2 ms-auto">
                            <span>2023</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default Educations;