import React from 'react';

function createProfileItem(content, index) {
    return <div key={index} className="row row-item">
        <div className="col-lg-7">
            <span><strong>{content.name}</strong></span>
            <span><em>{content.position}</em></span>
            <br />
            <ul>
                {content.descr.map((listItem, index) => { return <li key={index}>{listItem}</li> })}
            </ul>
        </div>
        <div className="col-lg-3 ms-auto">
            <span>{content.time}</span>
        </div>
    </div>
}
export default createProfileItem;